export default class DailyEvent {
  recordid: string;
  targetdate: string;
  affiliationid: string;
  eventname: string;
  isValid: string;
  constructor(
    recordid = "",
    targetdate = "",
    affiliationid = "",
    eventname = "",
    isValid = ""
  ) {
    this.recordid = recordid;
    this.targetdate = targetdate;
    this.affiliationid = affiliationid;
    this.eventname = eventname;
    this.isValid = isValid;
  }
}
